import React, { useEffect, useState } from "react"
import Breadcrumbs from "../../components/breadcrumbs"
import { useStaticQuery, graphql } from "gatsby"
import FeaturedImg from "../../components/featuredImg"

import Container from "react-bootstrap/Container"
import Layout from "../../components/layout"

import Button from "../../components/button"
import SideNav from "../../components/sidenav"
import CALinks from "./calinks"

import pages from "../../data/pages"
import { db } from "../../../utils/firebase"

const CustomManureHauling = () => {
  // console.log(pages)

  const data = useStaticQuery(graphql`
    query {
      featuredImgDesktop: file(
        relativePath: { eq: "20-MIM-0008_Secondary_CA-Services-5.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      featuredImgMobile: file(
        relativePath: { eq: "20-MIM-0008_Mobile-9.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  const [content, setContent] = useState("")
  useEffect(() => {
    // firebase.database().ref("/content")

    typeof window !== "undefined" &&
      db
        .collection("content")
        .doc("custom-manure-hauling")
        .get()
        .then(docref => {
          // console.log(docref.data())
          if (docref.exists) {
            setContent(docref.data().content)
          }
        })
        .catch(alert)
  }, [])

  return (
    <Layout id="about-us-page" className="secondary-page">
      <Breadcrumbs currentPath={[pages[0], pages[0].children[1]]}></Breadcrumbs>
      <div className="content-sidebar-wrapper">
        <SideNav header={pages[0].title} menu={pages[0].children}></SideNav>
        <Container className="main-content-left">
          <FeaturedImg
            data={data}
            html={`Custom <br> Manure <br> Hauling`}
          ></FeaturedImg>
          <section style={{ marginBottom: "16rem" }}>
            <article>
              <div dangerouslySetInnerHTML={{ __html: content }}></div>
              <Button
                style={{ width: "fit-content" }}
                label="Contact Us Today!"
                to={pages[1].path}
                className="smaller-text-mobile"
              ></Button>
            </article>
            <CALinks currentItem={pages[0].children[1].title}></CALinks>
          </section>
        </Container>
      </div>
    </Layout>
  )
}

export default CustomManureHauling
